@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}

html, body {
  font-family: 'Pretendard', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -.3px;
  color: #222;
  font-size: 16px;
  word-break: keep-all;
}

a {
  color: #3a7bd5;
  text-decoration: underline;
  transition: color .3s;
}
a:hover {
  color: #4D54FBCE;
}

@media screen and (max-width: 960px) {
  html, body {
    font-size: .9em;
  }
  body {
    padding-bottom: 120px;
  }
}